@import '~react-image-gallery/styles/css/image-gallery.css';
body {
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.price-label {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: -1;
  margin-top: 6px;
  padding: 4px 8px;
  transform: translateX(-50%);
  border-radius: 3px;
  background: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

.map-pin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: -2px;
  border-radius: 50%;
  background: white;
}

.map-pin::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 12px;
  height: 12px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background: #4caf50;
}

.feature-list-item::before {
  content: '\25CF';
  position: relative;
  font-size: 1.3em;
  font-weight: bold;
  top: 25px;
}
